<!-- 招生系统基础配置 -- 中考考试科目管理 -- 新增 -->
<template>
	<edit-template class="form" @confirm="submit" @cancel="$confirm('取消编辑?','提示',{type:'warning'}).then(()=>$router.back()).catch(()=>{});" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
		<h4 style="padding: 0 0 20rem 30rem">中考区域</h4>
		<el-form ref="form" :model="form" label-width="150rem" style="margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
			<el-form-item label="省份">
				{{ submitData.province }}
			</el-form-item>
			<el-form-item label="城市">
				{{ submitData.city }}
			</el-form-item>
			<el-form-item label="区/县">
				{{ submitData.area }}
			</el-form-item>
			<el-form-item label="考试年份">
				{{ submitData.year }}
			</el-form-item>
			<div>
				<el-form-item label="计分方式">
					{{ submitData.score_type_text }}
				</el-form-item>
				<el-form-item label="总分">
					{{ submitData.total_score }}
				</el-form-item>
				<el-form-item label="普高线">
					{{ submitData.score_line }}
				</el-form-item>
				<el-form-item v-if="submitData.freedom_line" label="自主划线分数">
					{{ submitData.freedom_line }}
				</el-form-item>
			</div>
		</el-form>
		<h4 style="padding: 50rem 0 20rem 30rem">考试区域</h4>
		<el-form label-position="top" style="text-align: center;padding: 0 24rem;margin-bottom: 24rem">
			<div v-for="(item, index) in submitData.examination_rank" :key="index" class="knowledge">
				<div class="flex" style="background: #f4f4f4;padding: 20rem 10rem">
					<el-form-item label="档位" label-width="40rem" style="margin-left: 12rem">
						<el-select v-model="item.rank_name" :popper-append-to-body="true">
							<el-option v-for="item in gearArr" :label="item.label" :value="item.value">
						</el-option></el-select>
					</el-form-item>
					<el-form-item v-if="submitData.score_type === 1 || submitData.score_type === 2" label="等级" label-width="120rem" style="margin-left: 12rem">
						<el-input v-model="item.rank_level" maxlength="10" placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item v-if="submitData.score_type === 2" label="最低分数" label-width="120rem" style="margin-left: 12rem">
						<el-input v-model="item.rank_score" maxlength="10" placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item v-if="submitData.score_type === 0 || submitData.score_type === 3" label="最低分数大于" label-width="120rem" style="margin-left: 12rem">
						<el-input v-model="item.rank_score_min" maxlength="10" placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item v-if="submitData.score_type === 0 || submitData.score_type === 3" label="最高分数小于等于" label-width="160rem" style="margin-left: 12rem">
						<el-input v-model="item.rank_score_max" maxlength="10" placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item v-if="submitData.city === '资阳市'" label="对应等级" label-width="80rem" style="margin-left: 12rem">
						<el-input v-model="item.rank_level" maxlength="10" placeholder="请输入" @input="$forceUpdate()"></el-input>
					</el-form-item>
				</div>
				<div class="flex" style="margin-left: 24rem">
					<el-button v-if="submitData.examination_rank.length !== 1" circle="" icon="el-icon-delete" @click="deleteKnowledge(index)"></el-button>
					<el-button circle="" icon="el-icon-plus" @click="addKnowledge"></el-button>
				</div>
			</div>
		</el-form>
		
	
</edit-template>
</template>

<script>

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
		return {
			backType: 0,
			form: {
				province: '',
				city: '',
				area: '',
				year: '',
			},
			provinceOptions: [],
			cityOptions: [],
			areaOptions: [],
			subjectOptions: [],
			examArea: [],
			gearArr: [], // 档位数据
			submitData: {
				examination_id: '',
				examination_rank: [
					{
						rank_name: "",
						rank_level: "",
						rank_score: "",
						rank_score_min: "",
						rank_score_max: ""
					},
				]
			}, // 提交数据
		}
	},
	created() {
		this.gearArr = []
		this.$_register.get('api/recruit/examination-rank/get-rank').then(res => {
			res.data.data.forEach(item => {
				this.gearArr.push({
					label: item,
					value: item,
					disabled: false
				})
			})
		})
		let params = {examination_id: this.$route.query.id}
		this.$_register.get('api/recruit/examination-rank/show', {params}).then(res => {
			this.submitData = res.data.data
		})
	},
	watch: {
		form: {
			deep: true,
			handler(val) {
				for (let valKey in val) {
					if (!val[valKey]) return
				}
				this.$_register.get('api/recruit/exam-conf/search', {params: val}).then(res => {
					this.examArea = res.data.data
					this.submitData.examination_id = res.data.data[0].id
				})
			}
		},
		submitData: {
			deep: true,
			handler(val) {
				let arr = val.examination_rank.map(item => item.rank_name)
				this.gearArr.forEach(item => {
					item.disabled = arr.includes(item.value)
				})
			}
		}
	},
	methods: {
		// 添加科目
		addKnowledge() {
			this.submitData.examination_rank.push({
				rank_name: "",
				rank_level: "",
				rank_score: "",
				rank_score_min: "",
				rank_score_max: ""
			})
		},

		// 删除科目
		deleteKnowledge(index) {
			this.submitData.examination_rank.splice(index, 1)
		},

		// 提交数据
		submit() {
			this.$refs.form.validate((res) => {
				if (res) {
					this.$_register.post("/api/recruit/examination-rank/edit", this.submitData).then(res => {
						this.$message({
							type: 'success',
							message: res.data.msg,
							onClose: () => {
								this.$store.commit("setPage", 1);
								this.backType = 1
								this.$router.back();
							}
						});
					})
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>

.form {
	padding-top: 24rem;
}

//::v-deep .el-form .el-input {
//	min-width: 250rem;
//}

.knowledge {
	margin-top: 24rem;
	display: flex;
	align-items: center;

	.knowledge_box {
		display: flex;
		padding-top: 12rem;
		height: 60rem;
		background-color: #f4f4f4;
	}

	.add_knowledge {
		display: flex;
	}
}

@media only screen and (max-width: 1399rem) {
	::v-deep .el-input {
		width: 180rem !important;
	}
}
</style>
